import axios, { AxiosError } from "axios";
import {
  AegisResponse,
  FormCompanyInfo,
  KickOffFormFields,
  SubcontractorFormFields,
  TrainingSubmission,
  VisitorFormSubmission,
  VisitorLogsSubmission,
} from "./api.types";

const BASE_URL =
  process.env.REACT_APP_EXTERNAL_BASE_URL ?? "http://localhost:5002";

const externalInstance = axios.create({
  baseURL: BASE_URL,
});

export async function sendTrainingSubmission(
  trainingSub: TrainingSubmission
): Promise<AegisResponse<null, null>> {
  try {
    const formData = new FormData();
    formData.append("name", trainingSub.name);
    formData.append("email", trainingSub.email);
    formData.append("cuiCertFile", trainingSub.cuiCert);
    formData.append("insiderThreatCertFile", trainingSub.insiderThreatCert);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const res = await externalInstance.post("/training", formData, {
      headers,
    });
    return res.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    }
  }
  return {
    isAegisResponse: true,
    success: true,
    code: 201,
    data: null,
  };
}

export async function sendVisitorLogsSubmission(
  visitorLogsSub: VisitorLogsSubmission
): Promise<AegisResponse<null, null>> {
  try {
    const formData = new FormData();
    formData.append("name", visitorLogsSub.name);
    formData.append("email", visitorLogsSub.email);
    formData.append("logsFile", visitorLogsSub.logsFile);
    formData.append("quarter", visitorLogsSub.quarter);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const res = await externalInstance.post("/visitor-logs", formData, {
      headers,
    });
    return res.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    }
  }
  return {
    isAegisResponse: true,
    success: true,
    code: 201,
    data: null,
  };
}

export async function sendVisitorFormSubmission(
  visitorLogsSub: VisitorFormSubmission
): Promise<AegisResponse<null, null>> {
  try {
    // convert to UTC
    if (visitorLogsSub.timeOfVisit) {
      visitorLogsSub.timeOfVisit = new Date(
        visitorLogsSub.timeOfVisit
      ).toISOString();
    } else {
      visitorLogsSub.timeOfVisit = new Date().toISOString();
    }
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await externalInstance.post("/visitors", visitorLogsSub, {
      headers,
    });
    return res.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    } else {
      throw error;
    }
  }
}

export async function sendKickOffFormSubmission(
  kickoffSubmission: KickOffFormFields
): Promise<AegisResponse<null, null>> {
  try {
    if (kickoffSubmission.call1StartTime) {
      kickoffSubmission.call1StartTime = new Date(
        kickoffSubmission.call1StartTime
      ).toISOString();
    }
    if (kickoffSubmission.call2StartTime) {
      kickoffSubmission.call2StartTime = new Date(
        kickoffSubmission.call2StartTime
      ).toISOString();
    }
    if (kickoffSubmission.call3StartTime) {
      kickoffSubmission.call3StartTime = new Date(
        kickoffSubmission.call3StartTime
      ).toISOString();
    }
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await externalInstance.post("/kickoff", kickoffSubmission, {
      headers,
    });
    return res.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    } else {
      throw error;
    }
  }
}

export async function sendSubContractorFormSubmission(
  subcontractorFormSub: SubcontractorFormFields
): Promise<AegisResponse<null, null>> {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await externalInstance.post(
      "/subcontractor",
      subcontractorFormSub,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    } else {
      throw error;
    }
  }
}

/**
 * @description gets subcontractor form data for one company
 */
export async function getCompanyInfo(
  shortform: string
): Promise<FormCompanyInfo | null> {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const res = await externalInstance.get(
      `/company-info?shortform=${shortform}`,
      {
        headers,
      }
    );
    return res.data.data;
  } catch (error) {
    const axErr = error as AxiosError;
    if (axErr.response && axErr.response.status === 404) {
      return null;
    }
    if (axErr.isAxiosError) {
      throw axErr.response?.data;
    } else {
      throw error;
    }
  }
}
