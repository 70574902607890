export function toLocalISOStringFromDatetimeLocal(inputValue: string) {
  // Parse the input value as a Date object
  const date = new Date(inputValue);

  // Get the offset in minutes and convert it to milliseconds
  const timezoneOffset = date.getTimezoneOffset() * 60000;

  // Adjust the date by the offset to get the local time in ISO format
  const localDate = new Date(date.getTime() - timezoneOffset);

  // Convert to ISO string and remove the 'Z' at the end
  const localISOString = localDate.toISOString().slice(0, -1);

  // Append the current timezone offset in the format "+/-HH:MM"
  const offsetSign = timezoneOffset > 0 ? "-" : "+";
  const offsetHours = String(Math.abs(timezoneOffset) / 60000 / 60).padStart(
    2,
    "0"
  );
  const offsetMinutes = String(
    (Math.abs(timezoneOffset) / 60000) % 60
  ).padStart(2, "0");

  return new Date(
    `${localISOString}${offsetSign}${offsetHours}:${offsetMinutes}`
  ).toISOString();
}
