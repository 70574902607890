import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import { StateSetter } from "../../../types";

export default function EmailInput({
  email,
  setEmail,
  emailInvalid,
  setEmailInvalid,
  isSubmitting,
}: Readonly<{
  email: string;
  setEmail: StateSetter<string>;
  emailInvalid: boolean;
  setEmailInvalid: StateSetter<boolean>;
  isSubmitting: boolean;
}>) {
  return (
    <FormControl isRequired isInvalid={emailInvalid} marginTop="16px">
      <FormLabel>Email</FormLabel>
      <Input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(event) => {
          setEmailInvalid(false);
          setEmail(event.target.value);
        }}
        isDisabled={isSubmitting}
        autoFocus
      />
      <FormErrorMessage>Email is required</FormErrorMessage>
    </FormControl>
  );
}
