import { useEffect } from "react";
import { Flex, Progress, Tag, Text } from "@chakra-ui/react";
import { useCompanyVisitorFormInfo } from "../../hooks/swr/company-visitors.hooks";
import { useAssertSession } from "../../providers/SupabaseAuthProvider";
import CompanyVisitorForm from "./components/CompanyVisitorForm";

export default function CompanyVisitorsFormPage() {
  const { access_token } = useAssertSession();
  const { data, error, isLoading } = useCompanyVisitorFormInfo(access_token);

  useEffect(() => {
    if (!data) {
      document.title = "Company Visitor Sign In";
    } else {
      document.title = `Atomus | ${data.companyName} Visitor Sign In`;
    }
  }, [data]);

  return (
    <Flex flexDir="column" gap="1rem">
      <Text fontSize="2xl" fontWeight="bold">
        {data && data.companyName} Visitor Sign-in
      </Text>
      {data && !error && <CompanyVisitorForm formInfo={data} />}
      {isLoading && <Progress isIndeterminate borderRadius="md" />}
      {error && <Tag colorScheme="red">Error loading company info</Tag>}
    </Flex>
  );
}
