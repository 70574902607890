import { ReportedErrorEvent, SourceLocation } from "../types/gcloud.types";

const SERVICE_NAME = "forms";

/**
 * @description log an error internally
 * @param error the error to log
 * @param reportLocation where in the code the error is being reported from
 * NOTE if reportLocations is not specified, the error stack will be used to
 * identify the source location (this is often preferred)
 * @param userEmail email of the user that experienced the error
 */
export function logError(
  error: Error,
  reportLocation?: SourceLocation,
  userEmail?: string
): void {
  (async () => {
    if (
      !process.env.REACT_APP_GCLOUD_PROJECT_ID ||
      !process.env.REACT_APP_ERROR_REPORTING_KEY ||
      process.env.NODE_ENV !== "production"
    ) {
      console.error(error);
      return;
    }

    let message: string;
    if (reportLocation) {
      message = `${error}`;
    } else if (error.stack) {
      message = error.stack;
    } else {
      // should never happen
      console.error("logError called without reportLocation or error.stack");
      return;
    }
    const reqBody: ReportedErrorEvent = {
      context: {
        httpRequest: {
          url: window.location.href,
          userAgent: navigator.userAgent,
        },
        reportLocation,
        user: userEmail,
      },
      eventTime: new Date().toISOString(),
      message,
      serviceContext: {
        service: SERVICE_NAME,
        version: process.env.VERSION ?? "1.0.0",
      },
    };
    await fetch(
      `https://clouderrorreporting.googleapis.com/v1beta1/projects/${process.env.NEXT_PUBLIC_GCLOUD_PROJECT_ID}/events:report?key=${process.env.NEXT_PUBLIC_ERROR_REPORTING_KEY}`,
      {
        body: JSON.stringify(reqBody),
        method: "POST",
      }
    );
  })().catch((error) =>
    console.error(`error reporting error: ${error.message}`)
  );
}
