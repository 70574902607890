import { Flex, HStack } from "@chakra-ui/react";
import { ReactComponent as Logomark } from "../../assets/logomark.svg";
import { ReactComponent as Logotype } from "../../assets/logotype.svg";

export default function Navbar() {
  return (
    <Flex>
      <HStack padding={6} borderRadius="md">
        <Logomark />
        <Logotype />
      </HStack>
    </Flex>
  );
}
