import { useReducer, useState } from "react";
import { VisitorFormSubmission } from "../../../api/api.types";
import { CompanyVisitorFormInfo } from "../../../types/company-visitors.types";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import FormContainer from "../../../components/FormContainer/FormContainer";
import { BsArrowRight } from "react-icons/bs";
import { useFormsToast } from "../../../hooks/toast.hooks";
import { toLocalISOStringFromDatetimeLocal } from "../../../utils/date.utils";
import { sendVisitorFormSubmission } from "../../../api/api.client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastFormError } from "../../../services/formerror.service";

type ReducerAction = {
  type: "update-field";
  data: {
    key: keyof VisitorFormSubmission;
    value: VisitorFormSubmission[keyof VisitorFormSubmission];
  };
};

function formReducer(
  state: VisitorFormSubmission,
  action: ReducerAction
): VisitorFormSubmission {
  switch (action.type) {
    case "update-field":
      return { ...state, [action.data.key]: action.data.value };
    default:
      return state;
  }
}

function getIsUsCitizenText(isUsCitizen: boolean | null): string {
  if (isUsCitizen === null) {
    return "";
  }
  return isUsCitizen ? "true" : "false";
}

function getDefaultValue(params: {
  companyAddress: string;
  searchParams: URLSearchParams;
}): VisitorFormSubmission {
  const defaultValue: VisitorFormSubmission = {
    email: "",
    employeeEmail: params.searchParams.get("employeeEmail") ?? "",
    employeeName: params.searchParams.get("employeeName") ?? "",
    isUsCitizen: null,
    location: params.searchParams.get("location") ?? params.companyAddress,
    name: "",
    phone: "",
    purposeOfVisit: params.searchParams.get("purposeOfVisit") ?? "",
    timeOfVisit: params.searchParams.get("timeOfVisit") ?? "",
  };
  return defaultValue;
}

function canSubmit(
  formData: VisitorFormSubmission,
  defaultTime: boolean
): boolean {
  for (const _key of Object.keys(formData)) {
    const key = _key as keyof VisitorFormSubmission;
    if (key === "timeOfVisit" && !defaultTime && !formData[key]) {
      // default time not checked and no input for time
      return false;
    }
    if (key === "timeOfVisit" && !defaultTime) {
      try {
        new Date(key);
      } catch {
        // missing fields in the datetime input
        return false;
      }
    }
    if (key === "isUsCitizen" && formData[key] === null) {
      return false;
    }
    if (
      key !== "timeOfVisit" &&
      key !== "isUsCitizen" &&
      !Boolean(formData[key])
    ) {
      return false;
    }
  }
  return true;
}

export default function CompanyVisitorForm({
  formInfo,
}: Readonly<{ formInfo: CompanyVisitorFormInfo }>) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formData, dispatch] = useReducer(
    formReducer,
    { companyAddress: formInfo.companyAddress, searchParams },
    getDefaultValue
  );
  const [defaultLocation, setDefaultLocation] = useState(
    formData.location === formInfo.companyAddress
  );
  const [defaultTime, setDefaultTime] = useState(
    !Boolean(formData.timeOfVisit)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { errorToast } = useFormsToast();
  const toast = useToast();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!canSubmit(formData, defaultTime) || !formData.location) {
      // should never happen because the button should be disabled
      errorToast("At least one form value is invalid or missing");
      return;
    }
    try {
      const newFormData: VisitorFormSubmission = {
        ...formData,
        timeOfVisit: defaultTime
          ? new Date().toISOString()
          : new Date(
              toLocalISOStringFromDatetimeLocal(formData.timeOfVisit ?? "")
            ).toISOString(),
        location: formData.location ?? formInfo.companyAddress,
      };
      await sendVisitorFormSubmission(newFormData);
      const redirectUrl = "/submit-success";
      const params = new URLSearchParams({
        form: "company-visitors",
        location: newFormData.location!,
        purposeOfVisit: newFormData.purposeOfVisit,
        employeeName: newFormData.employeeName,
        employeeEmail: newFormData.employeeEmail,
      });
      if (!defaultTime && formData.timeOfVisit) {
        params.set("timeOfVisit", formData.timeOfVisit);
      }
      navigate(`${redirectUrl}?${params.toString()}`);
    } catch (error) {
      toastFormError(toast, error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Text>
        This is a visitor sign-in form allowing our organization to meet
        compliance and security requirements.
      </Text>
      <FormContainer>
        <Flex flexDir="column" gap="16px">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <FormControl isRequired>
              <FormLabel>Visitor name</FormLabel>
              <Input
                required
                value={formData.name}
                onChange={(e) =>
                  dispatch({
                    type: "update-field",
                    data: { key: "name", value: e.target.value },
                  })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Visitor Phone Number</FormLabel>
              <Input
                required
                value={formData.phone}
                onChange={(e) =>
                  dispatch({
                    type: "update-field",
                    data: { key: "phone", value: e.target.value },
                  })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Visitor Email</FormLabel>
              <Input
                required
                value={formData.email}
                onChange={(e) =>
                  dispatch({
                    type: "update-field",
                    data: { key: "email", value: e.target.value },
                  })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>
                Is the visitor a U.S. Citizen / U.S. Person?
              </FormLabel>
              <Select
                required
                value={getIsUsCitizenText(formData.isUsCitizen)}
                onChange={(e) => {
                  let value: boolean | null;
                  if (e.target.value === "") {
                    value = null;
                  } else {
                    value = e.target.value === "true";
                  }
                  return dispatch({
                    type: "update-field",
                    data: { key: "isUsCitizen", value },
                  });
                }}
              >
                <option value="">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Location</FormLabel>
              <Flex flexDirection="row">
                <Checkbox
                  isChecked={defaultLocation}
                  onChange={(e) => {
                    setDefaultLocation(e.target.checked);
                    dispatch({
                      type: "update-field",
                      data: {
                        key: "location",
                        value: e.target.checked ? formInfo.companyAddress : "",
                      },
                    });
                  }}
                >
                  Use default company address
                </Checkbox>
              </Flex>
            </FormControl>
            {!defaultLocation && (
              <FormControl isRequired>
                <FormLabel fontWeight="normal">
                  Address of the location you are visiting
                </FormLabel>
                <Input
                  required
                  value={formData.location ?? ""}
                  onChange={(e) =>
                    dispatch({
                      type: "update-field",
                      data: { key: "location", value: e.target.value },
                    })
                  }
                />
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>Time of Visit</FormLabel>
              <Checkbox
                isChecked={defaultTime}
                onChange={(e) => {
                  setDefaultTime(e.target.checked);
                  dispatch({
                    type: "update-field",
                    data: {
                      key: "timeOfVisit",
                      value: e.target.checked ? new Date().toISOString() : "",
                    },
                  });
                }}
              >
                Now
              </Checkbox>
            </FormControl>
            {!defaultTime && (
              <FormControl>
                <Input
                  required
                  type="datetime-local"
                  value={formData.timeOfVisit ?? ""}
                  onChange={(e) => {
                    dispatch({
                      type: "update-field",
                      data: { key: "timeOfVisit", value: e.target.value },
                    });
                  }}
                />
              </FormControl>
            )}
            <FormControl isRequired>
              <FormLabel>Purpose of Visit</FormLabel>
              <Input
                required
                value={formData.purposeOfVisit}
                onChange={(e) =>
                  dispatch({
                    type: "update-field",
                    data: { key: "purposeOfVisit", value: e.target.value },
                  })
                }
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Who are you visiting?</FormLabel>
              <Select
                value={formData.employeeName}
                placeholder="Select an Employee"
                onChange={(e) => {
                  const user = formInfo.users.find(
                    (user) => user.name === e.target.value
                  );
                  if (user) {
                    dispatch({
                      type: "update-field",
                      data: { key: "employeeEmail", value: user.email },
                    });
                    dispatch({
                      type: "update-field",
                      data: { key: "employeeName", value: user.name },
                    });
                  } else {
                    dispatch({
                      type: "update-field",
                      data: { key: "employeeEmail", value: "" },
                    });
                    dispatch({
                      type: "update-field",
                      data: { key: "employeeName", value: "" },
                    });
                  }
                }}
              >
                {formInfo.users.map((user) => (
                  <option value={user.name} key={user.email}>
                    {user.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </form>
          <Text as="i" fontSize="sm">
            By submitting this form,
            <Text display="inline" as="b">
              {" "}
              you agree to not take any photos or videos within the organization
              facility without explicit permission.
            </Text>
          </Text>
          <Button
            marginTop={5}
            colorScheme="blue"
            width="fit-content"
            alignSelf="flex-end"
            rightIcon={<BsArrowRight />}
            onClick={handleSubmit}
            isDisabled={!canSubmit(formData, defaultTime)}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </FormContainer>
    </>
  );
}
