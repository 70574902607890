import { Box, Container } from "@chakra-ui/react";
import LoginPage from "../../pages/login/login-page";
import { useSupabaseAuth } from "../../providers/SupabaseAuthProvider";
import { Outlet } from "react-router-dom";
import NavbarWithLogout from "../NavbarWithLogout/NavbarWithLogout";

export default function AuthenticatedTemplate() {
  const { isLoading, session } = useSupabaseAuth();

  if (isLoading) {
    return <></>;
  }

  if (!isLoading && !session) {
    return <LoginPage />;
  }

  return (
    <Box>
      <NavbarWithLogout />
      <Container marginY={8}>
        <Box padding={1} marginY={8}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}
