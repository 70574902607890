import { Box, Container } from "@chakra-ui/react";
import { ReactNode } from "react";
import Navbar from "../Navbar/Navbar";

interface IPageProps {
  children?: ReactNode;
}

export default function Page({ children }: IPageProps) {
  return (
    <Box>
      <Navbar />
      <Container marginY={8}>
        <Box padding={1} marginY={8}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}
