import { useCallback } from "react";
import { Outlet } from "react-router-dom";
import { SWRConfig } from "swr";
import { SwrQueryError, WrappedError } from "../types/errors.types";
import { useSupabaseAuth } from "./SupabaseAuthProvider";
import { useFormsToast } from "../hooks/toast.hooks";
import { useErrorReporting } from "../hooks/logging.hooks";
import { logError } from "../utils/logging.utils";
import { SupabaseErrorMessages } from "../constants/supabase-auth.constants";

// separate provider so it has access to supabase auth context

export default function SWRProvider() {
  const { session, logout } = useSupabaseAuth();
  const { errorToast } = useFormsToast();
  const reportError = useErrorReporting();

  const onSwrError = useCallback(
    (error: any, key: string) => {
      if (error instanceof SwrQueryError) {
        if (
          error.wrappedError.message.includes(
            SupabaseErrorMessages.INVALID_JWT_MSG
          )
        ) {
          logout()
            .then(() =>
              errorToast(
                "Session expired. Please login again.",
                undefined,
                "session-expired"
              )
            )
            .catch((error) =>
              reportError(
                new WrappedError(
                  "error automatically logging out after session expiration",
                  error
                )
              )
            );
        } else {
          logError(error, error.getGoogleSourceLocation(), session?.user.email);
        }
      } else {
        // should never happen
        logError(new Error(`unknown swr error for key: ${key}: ${error}`));
      }
    },
    [errorToast, logout, reportError, session?.user.email]
  );

  return (
    <SWRConfig
      value={{
        errorRetryCount: 0,
        onError: onSwrError,
      }}
    >
      <Outlet />
    </SWRConfig>
  );
}
