import { ErrorAegisResponse } from "../api/api.types";
import { SourceLocation } from "./gcloud.types";
import { AtomusSwrKey } from "./swr.types";

export class ApiResponseError extends Error {
  constructor(statusCode: number, resultText?: string, sourceFunc?: Function) {
    super(`received status ${statusCode}: ${resultText}`);
    this.name = "ApiResponseError";
    Object.setPrototypeOf(this, ApiResponseError);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, sourceFunc ?? this.constructor);
    }
  }
}

export class AegisResponseError extends Error {
  public data: unknown;
  public code: number;
  public correlationId: string | undefined;
  public error: string;

  /**
   * @description error type that is created and thrown when an Atomus API responds
   * with an AegisErrorResponse type
   * @param errRes the error response from the api
   * @param sourceFunc the source function where the error was created (for
   * cleaner stack traces)
   */
  constructor(errRes: ErrorAegisResponse<unknown>, sourceFunc?: Function) {
    super(
      `received code ${errRes.code}: ${errRes.error} (correlation id: ${errRes.correlationId})`
    );
    this.name = "AegisResponseError";
    Object.setPrototypeOf(this, AegisResponseError.prototype);
    this.data = errRes.data;
    this.code = errRes.code;
    this.correlationId = errRes.correlationId;
    this.error = errRes.error;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, sourceFunc ?? this.constructor);
    }
  }
}

export class SwrQueryError extends Error {
  /**
   * @description a custom error that is created in swr's `onError` callback if
   * an swr query encounters and error
   * @param swrKey the atomus swr key that encountered the error
   * @param wrappedError the error that was thrown
   * @param sourceFunc the creation location of this error (for cleaner stack
   * traces)
   */
  constructor(
    public readonly swrKey: AtomusSwrKey,
    public readonly wrappedError: Error,
    sourceFunc?: Function
  ) {
    let message = `query id: ${swrKey.queryIdentifier}, query path: ${swrKey.path}`;
    if (swrKey.metadata) {
      message = `${message}, metadata: ${swrKey.metadata}`;
    }
    message = `${message}, message: ${wrappedError.message}`;
    super(message);
    this.name = "SwrQueryError";
    Object.setPrototypeOf(this, SwrQueryError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, sourceFunc ?? this.constructor);
    }
    if (wrappedError.stack) {
      this.stack = `${this.stack}\n${wrappedError.stack}`;
    }
  }

  public getGoogleSourceLocation(): SourceLocation {
    return {
      filePath: this.name,
      functionName: this.swrKey.queryIdentifier,
      lineNumber: -1,
    };
  }
}

export class WrappedError extends Error {
  constructor(message: string, wrappedError: Error) {
    super(`${message} (wrapped message: ${wrappedError.message})`);
    this.name = "WrappedError";
    Object.setPrototypeOf(this, WrappedError.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
    if (wrappedError.stack) {
      this.stack = `${this.stack}\n${wrappedError.stack}`;
    }
  }
}
