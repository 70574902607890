import { createClient, Session } from "@supabase/supabase-js";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet } from "react-router-dom";

interface ISupabaseAuthContext {
  session: Session | null;
  isLoading: boolean;
  sendOtp: (email: string) => Promise<Error | null>;
  verifyOtp: (email: string, otp: string) => Promise<Error | null>;
  loginWithPassword: (email: string, password: string) => Promise<Error | null>;
  logout: () => Promise<Error | null>;
}

const defaultErrFunc = () => {
  throw new Error("you must wrap your component in a SupabaseAuthProvider");
};

const defaultValue: ISupabaseAuthContext = {
  session: null,
  isLoading: false,
  sendOtp: defaultErrFunc,
  verifyOtp: defaultErrFunc,
  loginWithPassword: defaultErrFunc,
  logout: defaultErrFunc,
};

const SupabaseAuthContext = createContext(defaultValue);

export function useSupabaseAuth(): ISupabaseAuthContext {
  return useContext(SupabaseAuthContext);
}

/**
 * @description get the current supabase session, throws an error if the session
 * is not active/null
 * @returns the supabase session
 */
export function useAssertSession(): Session {
  const { session } = useSupabaseAuth();
  if (!session) {
    throw new Error("supabase session is null");
  }
  return session;
}

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL!,
  process.env.REACT_APP_SUPABASE_ANON_KEY!
);

export function SupabaseAuthProvider() {
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      setSession(data.session);
      setIsLoading(false);
    });
    const sub = supabase.auth.onAuthStateChange((_event, newSession) => {
      setSession(newSession);
      setIsLoading(false);
    });
    return () => sub.data.subscription.unsubscribe();
  }, []);

  /**
   * @description login via the email OTP flow
   * @param email email to send the OTP to
   * @returns null if the OTP was sent successfully, otherwise the error that occurred
   */
  const sendOtp = useCallback(async (email: string): Promise<Error | null> => {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
      },
    });
    return error;
  }, []);

  const verifyOtp = useCallback(
    async (email: string, otp: string): Promise<Error | null> => {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: "email",
      });
      return error;
    },
    []
  );

  const loginWithPassword = useCallback(
    async (email: string, password: string): Promise<Error | null> => {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      return error;
    },
    []
  );

  const logout = useCallback(async () => {
    const { error } = await supabase.auth.signOut();
    return error;
  }, []);

  const contextValue = useMemo<ISupabaseAuthContext>(
    () => ({
      session,
      isLoading,
      verifyOtp,
      sendOtp,
      logout,
      loginWithPassword,
    }),
    [session, isLoading, verifyOtp, sendOtp, logout, loginWithPassword]
  );

  return (
    <SupabaseAuthContext.Provider value={contextValue}>
      <Outlet />
    </SupabaseAuthContext.Provider>
  );
}
