import { useCallback } from "react";
import { SourceLocation } from "../types/gcloud.types";
import { useSupabaseAuth } from "../providers/SupabaseAuthProvider";
import { logError } from "../utils/logging.utils";

export function useErrorReporting() {
  const { session } = useSupabaseAuth();

  return useCallback(
    (error: Error, reportLocation?: SourceLocation) => {
      logError(error, reportLocation, session?.user.email);
    },
    [session]
  );
}
