import { Button, Flex, Spacer } from "@chakra-ui/react";
import { ReactComponent as Logomark } from "../../assets/logomark.svg";
import { ReactComponent as Logotype } from "../../assets/logotype.svg";
import { BsBoxArrowRight } from "react-icons/bs";
import { useSupabaseAuth } from "../../providers/SupabaseAuthProvider";

export default function NavbarWithLogout() {
  const { logout } = useSupabaseAuth();
  return (
    <Flex padding="24px">
      <Logomark />
      <Logotype />
      <Spacer />
      <Button
        size="xs"
        variant="outline"
        colorScheme="blue"
        leftIcon={<BsBoxArrowRight />}
        onClick={logout}
      >
        Logout
      </Button>
    </Flex>
  );
}
