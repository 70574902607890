import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import EmailInput from "./EmailInput";
import { SupabaseErrorMessages } from "../../../constants/supabase-auth.constants";
import { useSupabaseAuth } from "../../../providers/SupabaseAuthProvider";
import { StateSetter } from "../../../types";

export default function PasswordLogin({
  email,
  setEmail,
}: Readonly<{ email: string; setEmail: StateSetter<string> }>) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { loginWithPassword } = useSupabaseAuth();

  const handleLoginWithPassword = async () => {
    setIsSubmitting(true);
    setEmailInvalid(!email);
    setPasswordInvalid(!password);
    setErrMsg("");
    if (!email || !password) {
      setIsSubmitting(false);
      return;
    }
    const err = await loginWithPassword(email, password);
    if (err) {
      if (err.message === SupabaseErrorMessages.INVALID_CREDS) {
        setErrMsg("Invalid login credentials");
      } else if (err.message === SupabaseErrorMessages.SIGNUPS_NOT_ALLOWED) {
        setErrMsg("Email not found");
      } else {
        throw err;
      }
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleLoginWithPassword();
      }}
      style={{ width: "100%" }}
    >
      <Flex flexDir="column" gap="16px">
        <EmailInput
          email={email}
          emailInvalid={emailInvalid}
          isSubmitting={isSubmitting}
          setEmail={setEmail}
          setEmailInvalid={setEmailInvalid}
        />
        <FormControl isRequired isInvalid={passwordInvalid}>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(event) => {
              setPasswordInvalid(false);
              setPassword(event.target.value);
            }}
            isDisabled={isSubmitting}
          />
          <FormErrorMessage>Password is required</FormErrorMessage>
        </FormControl>
        {errMsg && (
          <Text color="red" fontSize="sm">
            {errMsg}
          </Text>
        )}
        <Button
          type="submit"
          colorScheme="blue"
          marginY="16px"
          rightIcon={<BsArrowRight />}
          isLoading={isSubmitting}
        >
          Login
        </Button>
      </Flex>
    </form>
  );
}
