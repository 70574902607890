import useSWR, { SWRResponse } from "swr";
import { CompanyVisitorFormInfo } from "../../types/company-visitors.types";
import { AtomusSwrKey } from "../../types/swr.types";
import { swrFetcher } from "../../utils/fetchers.utils";

export function useCompanyVisitorFormInfo(
  token: string
): SWRResponse<CompanyVisitorFormInfo, Error> {
  return useSWR(
    (): AtomusSwrKey => ({
      path: "/company-visitors/info",
      queryIdentifier: "companyVisitorsInfo",
      token,
    }),
    swrFetcher<CompanyVisitorFormInfo>
  );
}
