import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import EmailInput from "./EmailInput";
import { useSupabaseAuth } from "../../../providers/SupabaseAuthProvider";
import { StateSetter } from "../../../types";
import { SupabaseErrorMessages } from "../../../constants/supabase-auth.constants";

export default function OtpLogin({
  email,
  setEmail,
}: Readonly<{ email: string; setEmail: StateSetter<string> }>) {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const { sendOtp, verifyOtp } = useSupabaseAuth();

  const handleSendOtp = async () => {
    setIsSubmitting(true);
    setEmailInvalid(!email);
    setErrMsg("");
    if (!email) {
      setIsSubmitting(false);
      return;
    }
    const err = await sendOtp(email);
    if (err) {
      if (err.message === SupabaseErrorMessages.SIGNUPS_NOT_ALLOWED) {
        setErrMsg("Email not found.");
      } else {
        // triggers error.tsx
        throw err;
      }
    } else {
      setEmailSent(true);
    }
    setIsSubmitting(false);
  };

  const handleVerifyOtp = async () => {
    setIsSubmitting(true);
    setErrMsg("");
    const err = await verifyOtp(email, otp);
    if (err) {
      if (err.message === SupabaseErrorMessages.INVALID_OTP) {
        setErrMsg("Code is invalid or has expired");
      } else {
        throw err;
      }
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (!emailSent) {
          handleSendOtp();
        } else {
          handleVerifyOtp();
        }
      }}
      style={{ width: "100%" }}
    >
      <Flex flexDir="column" gap="16px" alignItems="stretch">
        {!emailSent && (
          <EmailInput
            email={email}
            emailInvalid={emailInvalid}
            isSubmitting={isSubmitting}
            setEmail={setEmail}
            setEmailInvalid={setEmailInvalid}
          />
        )}
        {emailSent && (
          <FormControl marginTop="16px">
            <FormLabel>Enter the code sent to your email</FormLabel>
            <Input
              value={otp}
              onChange={(e) => {
                if (e.target.value.length > 6) {
                  // max 6 character
                  return;
                }
                setOtp(e.target.value);
              }}
              isDisabled={isSubmitting}
              placeholder="6-digit code"
              type="number"
            />
          </FormControl>
        )}
        {errMsg && (
          <Text color="red" fontSize="sm">
            {errMsg}
          </Text>
        )}
        <Button
          colorScheme="blue"
          width="100%"
          rightIcon={<BsArrowRight />}
          isLoading={isSubmitting}
          onClick={!emailSent ? handleSendOtp : handleVerifyOtp}
          type="submit"
          isDisabled={emailSent && otp.length < 6}
        >
          {!emailSent ? "Send me a code" : "Login"}
        </Button>
        {emailSent && (
          <Button
            type="submit"
            variant="link"
            colorScheme="blue"
            size="sm"
            onClick={() => {
              setOtp("");
              setEmailSent(false);
            }}
          >
            Send me a new code
          </Button>
        )}
      </Flex>
    </form>
  );
}
