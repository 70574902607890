import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";

const UNIVERSAL_PARAMS = {
  position: "top-right",
  variant: "subtle",
  isCloseable: true,
} as const;

export function useFormsToast() {
  const toast = useToast();

  const errorToast = useCallback(
    (title: string, duration = 2000, id?: string) => {
      if (!id || !toast.isActive(id)) {
        toast({
          ...UNIVERSAL_PARAMS,
          title,
          duration,
          status: "error",
          isClosable: true,
          position: "top-left",
          id,
        });
      }
    },
    [toast]
  );

  const successToast = useCallback(
    (title: string, duration = 2000, id?: string) => {
      if (!id || !toast.isActive(id)) {
        toast({
          ...UNIVERSAL_PARAMS,
          title,
          duration,
          status: "success",
          isClosable: true,
          position: "top-left",
          id,
        });
      }
    },
    [toast]
  );

  return {
    errorToast,
    successToast,
  };
}
