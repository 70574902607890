"use client";
import { Box, Center, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import OtpLogin from "./components/OtpLogin";
import PasswordLogin from "./components/PasswordLogin";

export default function LoginPage() {
  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const handleToggleAuth = (event: KeyboardEvent) => {
      if ((event.metaKey || event.altKey) && event.code === "KeyK") {
        setIsPasswordLogin(!isPasswordLogin);
      }
    };
    window.addEventListener("keydown", handleToggleAuth);
    return () => window.removeEventListener("keydown", handleToggleAuth);
  }, [isPasswordLogin]);

  return (
    <Box background="blue.500" height="100vh" width="100vw">
      <Center>
        <Box
          marginTop="30vh"
          backgroundColor="white"
          shadow="lg"
          borderRadius="md"
        >
          <Center flexDir="column" gap="12px" padding="16px" minWidth="32rem">
            {/* <Image src={Logo.src} alt="atomus-logo" width="80px" /> */}
            <Heading fontWeight="semibold" size="md">
              Sign in to Atomus Forms
            </Heading>
            {isPasswordLogin ? (
              <PasswordLogin email={email} setEmail={setEmail} />
            ) : (
              <OtpLogin email={email} setEmail={setEmail} />
            )}
          </Center>
        </Box>
      </Center>
    </Box>
  );
}
