import { StatusCodes } from "http-status-codes";
import {
  isErrorAegisResponse,
  isSuccessAegisResponse,
} from "../services/formerror.service";
import {
  AegisResponseError,
  ApiResponseError,
  SwrQueryError,
} from "../types/errors.types";
import { AtomusSwrKey } from "../types/swr.types";

export type HttpMethod = "GET" | "POST" | "PATCH";

export type Fetcher = (
  path: string,
  method: HttpMethod,
  data?: any
) => Promise<any>;

export const API_BASE_URL =
  process.env.REACT_APP_EXTERNAL_BASE_URL ?? "http://localhost:5002";

export async function formsFetcher<T = any>(
  path: string,
  token: string,
  method: HttpMethod = "GET",
  data?: any,
  contentType = "application/json"
): Promise<T> {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  const headers: HeadersInit = {
    authorization: `Bearer ${token}`,
  };
  if (contentType !== "multipart/form-data") {
    headers["Content-Type"] = contentType;
  }
  const res = await fetch(`${API_BASE_URL}${path}`, {
    headers,
    method,
    body: contentType === "application/json" ? JSON.stringify(data) : data,
  });
  if (res.status === StatusCodes.NO_CONTENT) {
    return undefined as T;
  }
  const resContentType = res.headers.get("Content-Type");
  if (!resContentType?.startsWith("application/json")) {
    if (!res.ok) {
      let resultText: string | undefined;
      try {
        resultText = await res.text();
      } catch {}
      throw new ApiResponseError(res.status, resultText, formsFetcher);
    }
    throw new Error(
      `invalid content type for success response: ${resContentType}`
    );
  }
  const parsedRes = await res.json();
  if (isSuccessAegisResponse(parsedRes)) {
    return parsedRes.data as T;
  }
  if (isErrorAegisResponse(parsedRes)) {
    throw new AegisResponseError(parsedRes, formsFetcher);
  }
  if (!res.ok) {
    throw new Error(`received status ${res.status} (${res.statusText})`);
  }
  throw new Error("received unknown response type");
}

/**
 * @description a custom fetcher for use with SWR queries that wraps any error
 * thrown by `formsFetcher` in an SWRQueryError
 * @param swrKey the atomus swr key containing api request details and a query identifier
 * @param method optional http method for the api request
 * @param data optional data to send if sending a post request
 * @param contentType optional content type header if not application/json
 * @returns the result of the fetch
 * @throws SWRQueryError swr query error that wraps the error thrown by the fetcher
 */
export async function swrFetcher<T = any>(
  swrKey: AtomusSwrKey,
  method: HttpMethod = "GET",
  data?: any,
  contentType = "application/json"
): Promise<T> {
  try {
    return await formsFetcher<T>(
      swrKey.path,
      swrKey.token,
      method,
      data,
      contentType
    );
  } catch (error) {
    throw new SwrQueryError(swrKey, error as Error, swrFetcher);
  }
}
