import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import usePageTracking from "./components/utilities/usePageTracking";
import Home from "./pages/Home";
import SuccessPage from "./pages/SuccessPage";
import CatchAllRoute from "./pages/catchAllPath";
import KickOffForm from "./pages/kick-off";
import Referral from "./pages/referral";
import Support from "./pages/support";
import TrainingCertificateForm from "./pages/training";
import VisitorLogsForm from "./pages/visitor-logs";
import VisitorForm from "./pages/visitors";
import { SupabaseAuthProvider } from "./providers/SupabaseAuthProvider";
import CompanyVisitorsPage from "./pages/company-visitors/company-visitors";
import AuthenticatedTemplate from "./components/AuthenticatedTemplate/AuthenticatedTemplate";
import SwrProvider from "./providers/SwrProvider";
const renderLoading = () => (
  <>
    <Navbar />
  </>
);

export default function App() {
  usePageTracking();
  return (
    <Suspense fallback={renderLoading()}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/refer" element={<Referral />} />
        <Route path="/ticket" element={<Support />} />
        <Route path="/visitors" element={<VisitorForm />} />
        <Route element={<SupabaseAuthProvider />}>
          <Route element={<AuthenticatedTemplate />}>
            <Route element={<SwrProvider />}>
              <Route
                path="/company-visitors"
                element={<CompanyVisitorsPage />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="/Tx8209tD9fDz" element={<VisitorLogsForm />} />
        <Route path="/0242ac120002" element={<TrainingCertificateForm />} />
        <Route path="/training" element={<TrainingCertificateForm />} />
        <Route path="/visitor-logs" element={<VisitorLogsForm />} />
        <Route path="/kick-off" element={<KickOffForm />} />
        <Route path="/submit-success" element={<SuccessPage />} />
        <Route path="*" element={<CatchAllRoute />} />
      </Routes>
    </Suspense>
  );
}
